<template>
  <div>
    <component
      :is="
        $vuetify.breakpoint.smAndDown
          ? 'additional-data-tabs'
          : 'additional-data-card'
      "
      :guest="guest"
      :countries="countries"
      :states-options="statesOptions"
      :cities-options="citiesOptions"
      :transport-types="transportTypes"
      :purposes="purposes"
      :config="additionalDataConfig"
      @next="onNextTab"
      @submit="$store.dispatch('flow/guest/next')"
      @click:back="$store.dispatch('flow/guest/previous')"
      @change="onChange"
    />
  </div>
</template>

<script>
import find from "lodash.find"
import { onMounted } from "vue"
import {
  useNamespacedGetters,
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import { useTracking } from "@/composables/useTracking"

import { useChangeRegion } from "../useChangeRegion"
import AdditionalDataCard from "./components/AdditionalDataCard.vue"
import AdditionalDataTabs from "./components/AdditionalDataTabs.vue"

/**
 *
 */
export default {
  components: {
    AdditionalDataTabs,
    AdditionalDataCard,
  },
  setup() {
    const { currentLang } = useNamespacedState("layout", ["currentLang"])
    const { guest } = useNamespacedState("guest", ["guest"])
    const { transportTypes, purposes } = useNamespacedState("parameters", [
      "transportTypes",
      "purposes",
    ])
    const { changeGuest } = useNamespacedMutations("guest", ["changeGuest"])
    const { additionalDataConfig } = useNamespacedGetters("company", [
      "additionalDataConfig",
    ])
    const { trackEvent } = useTracking()

    const {
      countries,
      citiesOptions,
      statesOptions,
      onChangeArrivingCity,
      onChangeArrivingCountry,
      onChangeArrivingState,
      onChangeNextCity,
      onChangeNextState,
      onChangeNextCountry,
    } = useChangeRegion()

    const onChangeTransportType = ({ arrivingBy }) => {
      const transport = find(transportTypes.value, { id: arrivingBy })

      const value = transport[`name_${[currentLang.value]}`] ?? transport.name

      changeGuest({ arrivingByName: value })
    }

    const onChangePurposeTrip = ({ purposeTrip }) => {
      const purpose = find(purposes.value, { id: purposeTrip })

      const value = purpose[`name_${[currentLang.value]}`] ?? purpose.name

      changeGuest({ purposeTripName: value })
    }

    const onChange = (chunk) => {
      changeGuest(chunk)

      if ("arrivingBy" in chunk) onChangeTransportType(chunk)
      if ("purposeTrip" in chunk) onChangePurposeTrip(chunk)
      if ("arrivingFromCountryId" in chunk) onChangeArrivingCountry(chunk)
      if ("arrivingFromStateId" in chunk) onChangeArrivingState(chunk)
      if ("arrivingFrom" in chunk) onChangeArrivingCity(chunk)
      if ("nextDestinationCountryId" in chunk) onChangeNextCountry(chunk)
      if ("nextDestinationStateId" in chunk) onChangeNextState(chunk)
      if ("nextDestination" in chunk) onChangeNextCity(chunk)
    }

    const onNextTab = (tab) => trackEvent("substep_personal_info_" + tab)
    onMounted(() => trackEvent("step_additional_data"))

    return {
      guest,
      additionalDataConfig,
      citiesOptions,
      countries,
      purposes,
      statesOptions,
      transportTypes,

      onNextTab,
      onChange,
    }
  },
}
</script>
