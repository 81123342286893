import find from "lodash.find"
import {
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import { useStoreAction } from "@/composables"

/**
 * @returns Composable para gerenciar a troca de região (endereços)
 */
export function useChangeRegion() {
  return {
    ...useChangeTravelData(),
    ...useChangeAddress(),
  }
}

/**
 * Composable que retorna manipulações dos campos arrivingFrom
 * e nextDestination
 */
export function useChangeTravelData() {
  const { countries, statesOptions, citiesOptions } = useNamespacedState(
    "parameters",
    ["countries", "statesOptions", "citiesOptions"]
  )
  const { getCities } = useStoreAction("parameters", ["getCities"])
  const { getStates } = useStoreAction("parameters", ["getStates"])
  const { changeGuest } = useNamespacedMutations("guest", ["changeGuest"])

  /**
   *
   */
  const onChangeArrivingCountry = (chunk) => {
    const country = find(countries.value, {
      code: chunk.arrivingFromCountryId,
    })

    changeGuest({
      arrivingFromCountryId: chunk.arrivingFromCountryId,
      arrivingFromCountryName: country.name,
      arrivingFromStateId: "",
      arrivingFromStateName: "",
      arrivingFrom: "",
      arrivingFromName: "",
    })

    if (chunk.arrivingFromCountryId === "BR") {
      getStates({
        section: "origin",
        countryCode: chunk.arrivingFromCountryId,
      })
    }
  }

  /**
   *
   */
  const onChangeArrivingState = (chunk) => {
    const state = find(statesOptions.value.origin, {
      id: chunk.arrivingFromStateId,
    })
    changeGuest({
      arrivingFromStateId: chunk.arrivingFromStateId,
      arrivingFromStateName: state?.name ?? "",
      arrivingFrom: "",
      arrivingFromName: "",
    })
    getCities({ section: "origin", stateId: chunk.arrivingFromStateId })
  }

  /**
   *
   */
  const onChangeArrivingCity = (chunk) => {
    const city = find(citiesOptions.value.origin, {
      id: chunk.arrivingFrom,
    })

    changeGuest({
      arrivingFrom: chunk.arrivingFrom,
      arrivingFromName: city?.name ?? "",
    })
  }

  /**
   *
   */
  const onChangeNextCountry = (chunk) => {
    const country = find(countries.value, {
      code: chunk.nextDestinationCountryId,
    })

    changeGuest({
      nextDestinationCountryId: chunk.nextDestinationCountryId,
      nextDestinationCountryName: country?.name ?? "",
      nextDestinationStateId: "",
      nextDestinationStateName: "",
    })

    if (chunk.nextDestinationCountryId === "BR") {
      getStates({
        section: "destiny",
        countryCode: chunk.nextDestinationCountryId,
      })
    }
  }

  /**
   *
   */
  const onChangeNextState = (chunk) => {
    const state = find(statesOptions.value.destiny, {
      id: chunk.nextDestinationStateId,
    })

    changeGuest({
      nextDestinationStateId: chunk.nextDestinationStateId,
      nextDestinationStateName: state?.name ?? "",
    })
    getCities({ section: "destiny", stateId: chunk.nextDestinationStateId })
  }

  /**
   *
   */
  const onChangeNextCity = (chunk) => {
    const city = find(citiesOptions.value.destiny, {
      id: chunk.nextDestination,
    })

    changeGuest({
      nextDestination: chunk.nextDestination,
      nextDestinationName: city?.name ?? "",
    })
  }

  return {
    countries,
    statesOptions,
    citiesOptions,

    onChangeArrivingCity,
    onChangeArrivingState,
    onChangeArrivingCountry,
    onChangeNextCity,
    onChangeNextState,
    onChangeNextCountry,
  }
}

/**
 * Composable que retorna as manipulações dos campos
 * de endereço e cep
 */
export function useChangeAddress() {
  const { countries, statesOptions, citiesOptions } = useNamespacedState(
    "parameters",
    ["countries", "statesOptions", "citiesOptions"]
  )
  const { getCities } = useStoreAction("parameters", ["getCities"])
  const { getStates } = useStoreAction("parameters", ["getStates"])
  const { changeGuest } = useNamespacedMutations("guest", ["changeGuest"])
  const {
    tryFillByZipCode,
    isLoading: isLoadingCep,
    hasError: hasErrorZipCode,
  } = useStoreAction("guest", "tryFillByZipCode")

  /**
   *
   */
  const onChangeCountry = (chunk) => {
    const country = find(countries.value, { code: chunk.countryId })

    changeGuest({
      code: chunk.countryId,
      countryName: country?.name ?? "",
      mobilePhoneNumber: `+${country?.phoneDial ?? ""}`,
      mobilePhoneCountryCode: chunk.countryId ?? "",
      stateId: "",
      stateName: "",
      cityId: "",
      cityName: "",
      zipCode: "",
      neighborhood: "",
      address: "",
    })

    getStates({ section: "residence", countryCode: chunk.countryId })
  }

  /**
   *
   */
  const onChangeState = (chunk) => {
    const state = find(statesOptions.value.residence, { id: chunk.stateId })

    changeGuest({
      stateId: chunk.stateId,
      stateName: state?.name ?? "",
      cityId: "",
      cityName: "",
    })
    getCities({
      section: "residence",
      stateId: chunk.stateId,
    })
  }

  /**
   *
   */
  const onChangeCity = (chunk) => {
    const city = find(citiesOptions.value.residence, { id: chunk.cityId })

    changeGuest({
      cityId: chunk.cityId,
      cityName: city?.name ?? "",
    })
  }

  /**
   *
   */
  const onChangeZipCode = (chunk) => {
    if ("zipCode" in chunk && chunk.zipCode.length === 0) {
      changeGuest({
        stateId: "",
        stateName: "",
        cityId: "",
        cityName: "",
        neighborhood: "",
        address: "",
        addressNumber: "",
      })
    }

    if ("zipCode" in chunk && chunk.zipCode.length === 9) {
      tryFillByZipCode()
    }
  }

  return {
    countries,
    statesOptions,
    citiesOptions,

    tryFillByZipCode,
    isLoadingCep,
    hasErrorZipCode,

    onChangeZipCode,
    onChangeCity,
    onChangeState,
    onChangeCountry,
  }
}
