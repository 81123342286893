<template>
  <z-card :title="$t('reservation.additional_data')" width="100%">
    <template #content>
      <travel-data-section
        :config="config"
        :guest="guest"
        :purposes="purposes"
        :countries="countries"
        :states-options="statesOptions"
        :cities-options="citiesOptions"
        :transport-types="transportTypes"
        :get-error-messages="getErrorMessages"
        :v$="v$"
        @change="$emit('change', $event)"
      />

      <extra-data-section
        :config="config"
        :guest="guest"
        :get-error-messages="getErrorMessages"
        :v$="v$"
        @change="$emit('change', $event)"
      />

      <div class="btn-container">
        <z-btn
          width="130px"
          :text="$t('reservation.btn_voltar')"
          @click="$emit('click:back')"
        />
        <z-btn
          data-cy="next-btn"
          width="130px"
          primary
          :disabled="v$.$dirty && v$.$invalid"
          :text="$t('reservation.btn_next')"
          @click="onNext"
        />
      </div>
    </template>
  </z-card>
</template>

<script>
import useVuelidate from "@vuelidate/core"
import { computed, onMounted, ref, toRefs } from "vue"

import ExtraDataSection from "./sections/ExtraDataSection.vue"
import TravelDataSection from "./sections/TravelDataSection.vue"
import { useAdditionalInfoValidations } from "./useValidations"

export default {
  components: { ExtraDataSection, TravelDataSection },
  props: {
    guest: {
      type: Object,
      required: true,
    },
    countries: {
      type: Array,
      default: () => [],
    },
    statesOptions: {
      type: Object,
      default: () => {},
    },
    citiesOptions: {
      type: Object,
      default: () => {},
    },
    transportTypes: {
      type: Array,
      default: () => [],
    },
    purposes: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const tabs = ref([
      { id: "tab-1", text: "dados sobre a viagem" },
      { id: "tab-2", text: "dados adicionais" },
    ])
    const { guest } = toRefs(props)
    const { travelDataRules, extraDataRules, getErrorMessages } =
      useAdditionalInfoValidations({ config: props.config, guest })

    const rules = computed(() => {
      return {
        ...travelDataRules.value,
        ...extraDataRules.value,
      }
    })

    const v$ = useVuelidate(rules, guest)

    const onNext = () => {
      v$.value.$touch()

      if (!v$.value.$invalid) {
        emit("submit")
      }
    }

    onMounted(() => {
      v$.value.$reset()
    })

    return {
      tabs,
      v$,
      getErrorMessages,
      onNext,
    }
  },
}
</script>

<style lang="scss" scoped>
.btn-container {
  display: grid;
  column-gap: 10px;
  width: 100%;
  grid-template-columns: minmax(130px, min-content) minmax(130px, min-content);
  justify-content: end;
}
</style>
