<template>
  <div class="extra-data">
    <p class="text-body" style="padding: 0 15px">
      {{ $t("reservation.hotel_data_info") }}
    </p>

    <template v-for="(field, index) of config.additionalData">
      <dynamic-field
        :id="field.id"
        :key="index"
        :title="field.label"
        :type="field.type"
        :disabled="disabled"
        :html="field.text"
        :max-rate="+field.max"
        :mask="field.mask"
        :options="
          Array.isArray(field.dataSource)
            ? field.dataSource.map((f) => ({ label: f.name, value: f.val }))
            : []
        "
        :value="guest.additionalData[field.id]"
        :error-messages="getErrorMessages(v$.additionalData[field.id])"
        :class="{ 'full-line': !/^(text|number|date)$/.test(field.type) }"
        @change="onChange"
      />
    </template>

    <z-btn
      primary
      width="100%"
      :disabled="v$.$invalid && v$.$dirty"
      :text="$t('reservation.btn_next')"
      @click="onNext"
    />

    <z-btn
      width="100%"
      :text="$t('reservation.btn_voltar')"
      @click="$emit('click:back')"
    />
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core"
import { toRefs } from "vue-demi"

import DynamicField from "../DynamicField.vue"
import { useAdditionalInfoValidations } from "../useValidations"

export default {
  components: { DynamicField },
  props: {
    guest: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { guest } = toRefs(props)
    const { getErrorMessages, extraDataRules } = useAdditionalInfoValidations({
      config: props.config,
      guest,
    })

    const v$ = useVuelidate(extraDataRules, guest.value)

    const onChange = (chunk) => {
      const newChunk = Object.keys(chunk).reduce((obj, key) => {
        return { ...obj, [`additionalData.${key}`]: chunk[key] }
      }, {})

      emit("change", newChunk)
    }

    const onNext = () => {
      v$.value.$touch()

      if (!v$.value.$invalid) {
        emit("click:next")
      }
    }

    return {
      v$,
      getErrorMessages,
      onChange,
      onNext,
    }
  },
}
</script>

<style lang="scss" scoped>
.extra-data {
  display: grid;
  row-gap: 15px;
}
</style>
