<template>
  <z-card
    v-if="tabs.length === 1"
    :title="tabs[0].text"
    touchless
    class="tab-content"
    width="100%"
  >
    <template #content>
      <travel-data
        v-if="tabs[0].id === 'travel'"
        class="tab-content"
        :guest="guest"
        :countries="countries"
        :states-options="statesOptions"
        :cities-options="citiesOptions"
        :transport-types="transportTypes"
        :purposes="purposes"
        :config="config"
        @click:next="onNext"
        @change="$emit('change', $event)"
      />
      <extra-data
        v-else-if="tabs[0].id === 'extra'"
        class="tab-content"
        :guest="guest"
        :config="config"
        @click:next="onNext"
        @click:back="onBack"
        @change="$emit('change', $event)"
      />
    </template>
  </z-card>

  <z-tabs-card
    v-else
    grow
    :tabs="tabs"
    :value="currentTab"
    @change="currentTab = $event"
  >
    <template #travel>
      <travel-data
        class="tab-content"
        :guest="guest"
        :countries="countries"
        :states-options="statesOptions"
        :cities-options="citiesOptions"
        :transport-types="transportTypes"
        :purposes="purposes"
        :config="config"
        @click:next="onNext"
        @click:back="onBack"
        @change="$emit('change', $event)"
      />
    </template>

    <template #extra>
      <extra-data
        class="tab-content"
        :guest="guest"
        :config="config"
        @click:next="onNext"
        @click:back="onBack"
        @change="$emit('change', $event)"
      />
    </template>
  </z-tabs-card>
</template>

<script setup>
import { computed, onMounted, ref } from "vue"
import goTo from "vuetify/lib/services/goto"

import i18n from "@/i18n"

import ExtraData from "./tabs/ExtraData.vue"
import TravelData from "./tabs/TravelData.vue"

const props = defineProps({
  guest: {
    type: Object,
    required: true,
  },
  countries: {
    type: Array,
    default: () => [],
  },
  statesOptions: {
    type: Object,
    default: () => {},
  },
  citiesOptions: {
    type: Object,
    default: () => {},
  },
  transportTypes: {
    type: Array,
    default: () => [],
  },
  purposes: {
    type: Array,
    default: () => [],
  },
  config: {
    type: Object,
    required: true,
  },
  initialTab: {
    type: String,
    default: "travel",
  },
})

const emit = defineEmits(["submit", "click:back"])

const currentTab = ref("travel")

const tabs = computed(() => {
  const currentTabs = []

  if (
    !props.config.travelData.arrivingFromCountry.default ||
    !props.config.travelData.arrivingFromState.default ||
    !props.config.travelData.arrivingFromCity.default ||
    !props.config.travelData.nextDestinationCountry.default ||
    !props.config.travelData.nextDestinationState.default ||
    !props.config.travelData.nextDestinationCity.default ||
    !props.config.travelData.purposeTrip.default ||
    !props.config.travelData.transportType.default
  ) {
    currentTabs.push({
      id: "travel",
      text: i18n.tc("reservation.about_travel_data"),
      icon: currentTab.value === "extra" ? "$check_rounded" : null,
      disabled: false,
    })
  }

  if (Object.keys(props.config.additionalData).length > 0) {
    currentTabs.push({
      id: "extra",
      text: i18n.tc("reservation.additional_data"),
      disabled: currentTab.value === "travel",
    })
  }

  return currentTabs
})

const computedCurrentTab = computed({
  get() {
    return tabs.value[0].id === "travel" ? "travel" : "extra"
  },

  set(newValue) {
    currentTab.value = newValue
  },
})

const onNext = () => {
  const currentIndex = tabs.value.findIndex(
    (tab) => tab.id === currentTab.value
  )
  const isLast = currentIndex === tabs.value.length - 1

  if (isLast) {
    emit("submit")
  } else {
    goTo(0)
    computedCurrentTab.value = tabs.value[currentIndex + 1].id
    emit("next", computedCurrentTab.value)
  }
}

const onBack = () => {
  const currentIndex = tabs.value.findIndex(
    (tab) => tab.id === currentTab.value
  )
  const isFirst = currentIndex === 0

  if (isFirst) {
    emit("click:back")
  } else {
    goTo(0)
    computedCurrentTab.value = tabs.value[currentIndex - 1].id
  }
}

onMounted(() => {
  currentTab.value = props.initialTab
})
</script>

<style lang="scss" scoped>
.btn-container {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
}
.tab-content {
  padding: 12px;
}
</style>
