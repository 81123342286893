<template>
  <div class="travel-data">
    <p class="text-body" style="padding: 15px">
      {{ $t("reservation.travel_data_info") }}
    </p>
    <z-section-divider
      v-if="
        !config.travelData?.arrivingFromCountry?.default ||
        !config.travelData?.arrivingFromState?.default ||
        !config.travelData?.arrivingFromCity?.default
      "
      :title="$t('reservation.origin_section')"
      start-opened
      :icon-down="''"
      :icon-up="''"
    >
      <template #content>
        <z-autocomplete
          v-if="!config.travelData?.arrivingFromCountry?.default"
          data-cy="origin-country"
          :autocomplete="Math.random()"
          :label="$t('reservation.country')"
          :items="countries"
          :error-messages="getErrorMessages(v$.arrivingFromCountryId)"
          :value="guest.arrivingFromCountryId"
          item-text="name"
          item-value="code"
          @change="
            $emit('change', {
              arrivingFromCountryId: $event,
            })
          "
        />
        <template v-if="!config.travelData?.arrivingFromState?.default">
          <z-autocomplete
            v-if="guest.arrivingFromCountryId === 'BR'"
            data-cy="origin-state"
            :autocomplete="Math.random()"
            :label="$t('reservation.state')"
            :items="statesOptions.origin"
            :error-messages="getErrorMessages(v$.arrivingFromStateId)"
            :value="guest.arrivingFromStateId"
            item-text="name"
            item-value="id"
            @change="$emit('change', { arrivingFromStateId: $event })"
          />
          <z-input
            v-else
            :label="$t('reservation.optional_state')"
            :error-messages="getErrorMessages(v$.arrivingFromStateName)"
            :value="guest.arrivingFromStateName"
            @change="$emit('change', { arrivingFromStateName: $event })"
          />
        </template>
        <template v-if="!config.travelData?.arrivingFromCity?.default">
          <z-autocomplete
            v-if="guest.arrivingFromCountryId === 'BR'"
            data-cy="origin-city"
            :autocomplete="Math.random()"
            :label="$t('reservation.city')"
            :items="citiesOptions.origin"
            :error-messages="getErrorMessages(v$.arrivingFrom)"
            :value="guest.arrivingFrom"
            item-text="name"
            item-value="id"
            @change="$emit('change', { arrivingFrom: $event })"
          />
          <z-input
            v-else
            :label="$t('reservation.optional_city')"
            :error-messages="getErrorMessages(v$.arrivingFromName)"
            :value="guest.arrivingFromName"
            @change="$emit('change', { arrivingFromName: $event })"
          />
        </template>
      </template>
    </z-section-divider>
    <z-section-divider
      v-if="
        !config.travelData?.nextDestinationCountry?.default ||
        !config.travelData?.nextDestinationState?.default ||
        !config.travelData?.nextDestinationCity?.default
      "
      :title="$t('reservation.next_destination_section')"
      start-opened
      :icon-down="''"
      :icon-up="''"
    >
      <template #content>
        <z-autocomplete
          v-if="!config.travelData?.nextDestinationCountry?.default"
          data-cy="next-country"
          :autocomplete="Math.random()"
          :label="$t('reservation.country')"
          :items="countries"
          :error-messages="getErrorMessages(v$.nextDestinationCountryId)"
          :value="guest.nextDestinationCountryId"
          item-text="name"
          item-value="code"
          @change="
            $emit('change', {
              nextDestinationCountryId: $event,
            })
          "
        />
        <template v-if="!config.travelData?.nextDestinationState?.default">
          <z-autocomplete
            v-if="guest.nextDestinationCountryId === 'BR'"
            data-cy="next-state"
            :autocomplete="Math.random()"
            :label="$t('reservation.state')"
            :items="statesOptions.destiny"
            :error-messages="getErrorMessages(v$.nextDestinationStateId)"
            :value="guest.nextDestinationStateId"
            item-text="name"
            item-value="id"
            @change="
              $emit('change', {
                nextDestinationStateId: $event,
              })
            "
          />
          <z-input
            v-else
            :label="$t('reservation.optional_state')"
            :error-messages="getErrorMessages(v$.nextDestinationStateName)"
            :value="guest.nextDestinationStateName"
            @change="$emit('change', { nextDestinationStateName: $event })"
          />
        </template>
        <template v-if="!config.travelData?.nextDestinationCity?.default">
          <z-autocomplete
            v-if="guest.nextDestinationCountryId === 'BR'"
            data-cy="next-city"
            :autocomplete="Math.random()"
            :label="$t('reservation.city')"
            :items="citiesOptions.destiny"
            :error-messages="getErrorMessages(v$.nextDestination)"
            :value="guest.nextDestination"
            item-text="name"
            item-value="id"
            @change="$emit('change', { nextDestination: $event })"
          />
          <z-input
            v-else
            :label="$t('reservation.optional_city')"
            :error-messages="getErrorMessages(v$.nextDestinationName)"
            :value="guest.nextDestinationName"
            @change="$emit('change', { nextDestinationName: $event })"
          />
        </template>
      </template>
    </z-section-divider>
    <z-section-divider
      v-if="!config.travelData?.transportType?.default"
      :title="$t('reservation.transport_type')"
      start-opened
      :icon-down="''"
      :icon-up="''"
    >
      <template #content>
        <z-select
          v-if="!config.travelData?.transportType?.default"
          data-cy="transport-type"
          :autocomplete="Math.random()"
          :label="$t('reservation.transport')"
          :items="transportTypes"
          :error-messages="getErrorMessages(v$.arrivingBy)"
          :value="guest.arrivingBy"
          item-text="name_pt"
          item-value="id"
          @change="$emit('change', { arrivingBy: $event })"
        />
      </template>
    </z-section-divider>
    <z-section-divider
      v-if="!config.travelData?.purposeTrip?.default"
      :title="$t('reservation.travel_purpose')"
      start-opened
      :icon-down="''"
      :icon-up="''"
    >
      <template #content>
        <z-select
          data-cy="travel-purpose"
          :autocomplete="Math.random()"
          :label="$t('reservation.travel_purpose')"
          :items="purposes"
          :error-messages="getErrorMessages(v$.purposeTrip)"
          :value="guest.purposeTrip"
          item-text="name_pt"
          item-value="id"
          @change="$emit('change', { purposeTrip: $event })"
        />
      </template>
    </z-section-divider>
    <div class="btn-container">
      <z-btn
        data-cy="next-btn"
        primary
        :disabled="v$.$invalid && v$.$dirty"
        :text="$t('reservation.btn_next')"
        @click="onNext"
      />
      <z-btn
        :text="$t('reservation.btn_voltar')"
        @click="$emit('click:back')"
      />
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core"
import { toRefs } from "vue-demi"

import { useAdditionalInfoValidations } from "../useValidations"

export default {
  props: {
    guest: {
      type: Object,
      required: true,
    },
    countries: {
      type: Array,
      default: () => [],
    },
    statesOptions: {
      type: Object,
      default: () => {},
    },
    citiesOptions: {
      type: Object,
      default: () => {},
    },
    transportTypes: {
      type: Array,
      default: () => [],
    },
    purposes: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { guest } = toRefs(props)
    const { travelDataRules, getErrorMessages } = useAdditionalInfoValidations({
      config: props.config,
      guest,
    })

    const v$ = useVuelidate(travelDataRules, guest)

    const onNext = () => {
      v$.value.$touch()

      if (!v$.value.$invalid) {
        emit("click:next")
      }
    }

    return {
      v$,
      travelDataRules,
      getErrorMessages,
      onNext,
    }
  },
}
</script>

<style lang="scss" scoped>
.btn-container {
  display: grid;
  row-gap: 10px;
  width: 100%;
  grid-template-columns: 1fr;
  justify-content: end;
}
</style>
